
export class BD{
    bdomobj:any;
    exist:boolean = false;
    type:string = "";

    constructor(domobj:string){
        
        if(domobj.charAt(0) == "."){
            domobj = domobj.substring(1);
            this.type = "class"
            this.bdomobj = document.getElementsByClassName(domobj)
            if (this.bdomobj != null) {
                this.exist = true
            }else{
                console.log('Error: this class '+domobj+' not exist in dom')
            }
        }
        if(domobj.charAt(0) == "#"){
            domobj = domobj.substring(1);
            this.type = "id"
            this.bdomobj = document.getElementById(domobj)
            if (this.bdomobj != null) {
                this.exist = true
            }else{
                console.log('Error: this id '+domobj+' not exist  in dom')
            }
        }
        if(domobj.charAt(0) == "@"){
            domobj = domobj.substring(1);
            this.type = "name"
            
            this.bdomobj = document.querySelectorAll("select[name='"+domobj+"']");
            if (this.bdomobj != null) {
                this.exist = true
            }else{
                console.log('Error: this name '+domobj+' not exist  in dom')
            }
        }
    }

    get bd(){
        return this.bdomobj;
    }

    get html():string{
        if(this.exist){
            return this.bdomobj.innerHTML;
        }else{
            return "";
        }
    }

    set html(value:string){
        if(this.exist)
            this.bdomobj.innerHTML = value;
    }

  

    animation(animation:string):void{
        this.bdomobj.style.animation = animation;
    }
    hide():void{
        this.bdomobj.style.display = "none";
    }

    show():void{
        this.bdomobj.style.display = "block";
    }

    ishide():boolean{
        if(this.bdomobj.style.display == "none"){
            return true;
        }
        return false;
    }

    isChecked():boolean{
        return this.bdomobj.checked
    }

    appendTo(domobjappend:any):void{
        this.bdomobj.appendChild = domobjappend
    }

    appendToTxtMode(txt:string):void{
        let p = document.createTextNode(txt) 
        this.bdomobj.appendChild(p)
    }
    appendChild(domobjappend:any):void{
        this.bdomobj.appendChild(domobjappend)
    }
    countChild():number{
        return this.bdomobj.childElementCount
    }
    
    remove(){
        
        if(this.type == "id"){
            this.bdomobj.remove()
        }
        if(this.type == "class"){
            for (let i = 0; i < this.bdomobj.length; i++) {
                this.bdomobj[i].remove()
            }
        }
    }
    addClass(cssclass:string){
        
        if(this.type == "id"){
            this.bdomobj.classList.add(cssclass)
        }
        if(this.type == "class"){
            for (let i = 0; i < this.bdomobj.length; i++) {
                this.bdomobj[i].classList.add(cssclass)
            }
        }
    }
    remClass(cssclass:string){
        if(this.type == "id"){
            this.bdomobj.classList.remove(cssclass)
        }
        if(this.type == "class"){
            for (let i = 0; i < this.bdomobj.length; i++) {
                this.bdomobj[i].classList.remove(cssclass)
            }
        }
    }
    getAttribute(attr:string){
        if(this.type == "id"){
           return this.bdomobj.getAttribute(attr)
        }
        if(this.type == "class"){
            return this.bdomobj[0].getAttribute(attr)
        }
        
    }
    get val():string{
        if(this.exist){
            return this.bdomobj.value;
        }else{
            return "";
        }
    }

    set val(value:string){
        if(this.exist)
            this.bdomobj.value = value;
    }

    onclick(f:any):void{
        if(this.type == "id"){
            this.bdomobj.addEventListener('click', f);
        }
        if(this.type == "class"){
            for (let i = 0; i < this.bdomobj.length; i++) {
                this.bdomobj[i].addEventListener('click', f);
            }
        }
    }

    onchange(f:any):void{
        if(this.type == "id"){
            this.bdomobj.addEventListener('change', f);
        }
        if(this.type == "class"){
            for (let i = 0; i < this.bdomobj.length; i++) {
                this.bdomobj[i].addEventListener('change', f);
            }
        }
    }
}
