export function modalTemplate(title:string, body:string, footer:string) {
    //<div class="modal" id="target-modal-1">
    return `
        <div class="modal-container">
            <div class="modal-header">
                <span class="close-btn">&times;</span>
                ${title}
            </div>
            <div class="modal-content">
            ${body}
            </div>
            <div class="modal-footer">
                ${footer}
                <button class="close-btn modal-btn-close">Ok</button>
            </div>
        </div>
    `;
    //</div>
}