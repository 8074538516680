import {modalTemplate} from './../tpl/modal'
import { BD } from '../domutility';

export function modal(title:string, body:string, footer:string){
    let modalWindow = modalTemplate(title, body, footer)
    var div = document.createElement('div');
    div.classList.add("modal-autogen")
    div.classList.add("show")
    div.innerHTML = modalWindow.trim();

    document.body.appendChild(div)
    let modalCloser = new BD('.close-btn');
    let modal = new BD('.modal-autogen');
    
    modalCloser.onclick((target:any) => {
        modal.remClass("show")
        modal.remove();
    })
    
    
}